import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [8,2];

export const dictionary = {
		"/admin": [22,[8]],
		"/admin/image-diff": [23,[8]],
		"/admin/prompt": [24,[8]],
		"/(signedIn)/edit": [10,[2,3]],
		"/(signedIn)/edit/[projectId]": [11,[2,3]],
		"/(signedIn)/edit/[projectId]/[editId]": [12,[2,3]],
		"/(signedOut)/error": [20,[7]],
		"/(signedIn)/eye": [13,[2,4]],
		"/(signedIn)/invite/[code]": [14,[2]],
		"/(signedOut)/login": [~21,[7]],
		"/(signedIn)/models/[[modelId]]": [15,[2]],
		"/(signedIn)/settings": [16,[2,5]],
		"/(signedIn)/settings/store": [17,[2,5]],
		"/(signedIn)/specimens": [18,[2,6]],
		"/(signedIn)/waitlist": [~19,[2]],
		"/(signedIn)/[[projectId]]/[[stackId]]/[[fileId]]": [9,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';